@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background: url(../img/bg.jpg) no-repeat;
    background-size: cover;
    margin: 0;
}

.main {
    padding-top: 80px;
}

.alreadyplayedMain {
    font-family: 'Poppins', sans-serif;
    color:red;
    font-size: 40px;
}
.alreadyplayedWord{
    font-family: 'Poppins', sans-serif;
    color: black;
    font-size: 30px;
}

.email {
    font-family: 'Poppins', sans-serif;
    color: black;
    font-size: 16px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main .circle button {
    background-color: #8852A8;
    color: yellow;
    border: none;
    font-size: 1.6em;
    cursor: pointer;
}

.main .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #8852A8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
}


    .main .boxes {
        display: flex;
        justify-content: center;
        padding-top: 100px;
    }

        .main .boxes .box {
            position: relative;
        }

        .main .boxes button {
            padding: 0;
            margin-right: 25px;
            border: none;
            cursor: pointer;
        }

            .main .boxes button .box {
                margin-right: 0;
            }

        .main .boxes .box {
            /* background: #e12014;*/
            font-family: 'Poppins', sans-serif;
            background: #b85bcf;
            width: 120px;
            height: 120px;
            border-radius: 5px;
            border: none;
            color: #fff;
            padding: 10px;
            font-size: 110px;
            margin-right: 25px;
            text-transform: uppercase;
            box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 30%);
            display: flex;
            align-items: center;
            justify-content: center;
        }

            .main .boxes .box p,
            .main .letters .text-box p {
                margin: 0;
            }

            .main .boxes .box span {
                position: absolute;
                bottom: 5px;
                color: #fff;
                font-size: 28px;
                right: 15px;
                z-index: 999;
            }


        .main .boxes .boxWin {
            font-family: 'Poppins', sans-serif;
            background: #b85bcf;
            width: 120px;
            height: 120px;
            border-radius: 5px;
            border: none;
            color: #fff;
            padding: 10px;
            font-size: 110px;
            margin-right: 25px;
            text-transform: uppercase;
            box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 30%);
            display: flex;
            align-items: center;
            justify-content: center;
        }

    .main .boxes .boxWin p,
    .main .letters .text-box p {
        margin: 0;
    }

            .main .boxes .boxWin span {
                position: absolute;
                bottom: 5px;
                color: #fff;
                font-size: 28px;
                right: 15px;
                z-index: 999;
            }

        .main .boxes .boxWin {
            position: relative;
        }

        .main .boxes .boxLose {
            background: #e12014;
            width: 120px;
            height: 120px;
            border-radius: 5px;
            border: none;
            color: #fff;
            padding: 10px;
            font-size: 110px;
            margin-right: 25px;
            text-transform: uppercase;
            box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 30%);
            display: flex;
            align-items: center;
            justify-content: center;
        }

            .main .boxes .boxLose p,
            .main .letters .text-box p {
                margin: 0;
            }

            .main .boxes .boxLose span {
                position: absolute;
                bottom: 5px;
                color: #fff;
                font-size: 28px;
                right: 15px;
                z-index: 999;
            }

.main .boxes .boxLose {
    position: relative;
}


        /* .main .boxes .box input:focus-visible,
.main .letters .text-box input:focus-visible{
    outline: none;
} */
    /* initial version
        .main .boxes button:nth-child(2) .box {
            background: #b85bcf;
        }

        .main .boxes button:nth-child(3) .box {
            background: #13743f;
        }

        .main .boxes button:nth-child(5) .box {
            background: #6f71d3;
        }

        .main .boxes button:nth-child(6) .box {
            background: #e07e0f;
        }

        .main .boxes .box:nth-child(2) {
            background: #b85bcf;
        }

        .main .boxes .box:nth-child(3) {
            background: #13743f;
        }

        .main .boxes .box:nth-child(5) {
            background: #6f71d3;
        }

        .main .boxes .box:nth-child(6) {
            background: #e07e0f;
        }

            */

        .main .boxes button:nth-child(2) .box {
            background: #6f71d3;
        }

        .main .boxes button:nth-child(3) .box {
            background: #6f71d3;
        }

        .main .boxes button:nth-child(4) .box {
            background: #6f71d3;
        }

        .main .boxes button:nth-child(5) .box {
            background: #6f71d3;
        }

        .main .boxes button:nth-child(6) .box {
            background: #6f71d3;
        }

        .main .boxes .box:nth-child(2) {
            background: #6f71d3;
        }

        .main .boxes .box:nth-child(3) {
            background: #6f71d3;
        }

        .main .boxes .box:nth-child(4) {
            background: #6f71d3;
        }

        .main .boxes .box:nth-child(5) {
            background: #6f71d3;
        }

        .main .boxes .box:nth-child(6) {
            background: #6f71d3;
        }

    .main .letters .text-box .inner .sub-inner {
        position: relative;
    }

.main .letters .text-box .sub-inner {
    font-family: 'Poppins', sans-serif;
    background: #13743f;
    width: 120px;
    height: 120px;
    border-radius: 5px;
    border: none;
    color: #fff;
    padding: 10px;
    font-size: 110px;
    margin-right: 25px;
    text-transform: uppercase;
    box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 30%);
    display: flex;
    align-items: center;
    justify-content: center;
}

    .main .letters .text-box {
        margin: 50px auto;
    }

        .main .letters .text-box span {
            position: absolute;
            bottom: 5px;
            color: #fff;
            font-size: 28px;
            right: 10px;
            z-index: 999;
        }

        .main .letters .text-box .inner {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .main .letters .text-box .letters-left {
            padding-left: 15px;
        }

            .main .letters .text-box .letters-left ins {
                display: block;
                text-decoration: none;
                text-align: left;
                font-size: 18px;
            }

    .main .numbers {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        position: relative;
    }

    .main .content:before {
        content: "";
        background: url(../img/bg.jpg) no-repeat;
        background-size: cover;
        width: 100%;
        height: 0px;
        display: block;
        position: absolute;
        z-index: -1;
        opacity: 0.25;
    }

    .main .numbers .num span {
        background: #ff6e68;
        width: 80px;
        height: 40px;
        margin-right: 25px;
        border-radius: 5px;
        border: none;
        color: #fff;
        padding: 10px;
        font-size: 40px;
        text-align: center;
        text-transform: uppercase;
        box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 30%);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    .main .numbers .num:nth-child(2) span {
        background: #6a70e7;
    }

    .main .numbers .num:nth-child(3) span {
        background: #ff943a;
    }

    .main .numbers .num:nth-child(4) span {
        background: #c358e2;
    }

    .main .numbers .num:nth-child(5) span {
        background: #e91b14;
    }

    .main .numbers .num:nth-child(6) span {
        background: #027443;
    }

.discard-btn {
    margin: 50px 0;
}

    .discard-btn button {
        background: #1b1a18;
        color: #fff;
        font-size: 20px;
        border-radius: 50px;
        padding: 7px 25px;
        cursor: pointer;
        border: none;
    }

.current-score button {
    background: #0e7440;
    font-size: 42px;
    color: #fff;
    padding: 15px 70px;
    border-radius: 50px;
    border: none;
    margin-bottom: 30px;
}

    .current-score button b {
        color: #ffaa05;
    }

.discard-text {
    margin: 50px 0;
}

    .discard-text p {
        margin: 0;
        font-size: 22px;
    }

        .discard-text p span {
            font-weight: 600;
        }

.subscribe {
    padding-bottom: 50px;
}

    .subscribe input {
        background: #c9ece5;
        border: none;
        height: 65px;
        width: 400px;
        display: block;
        margin: auto;
        font-size: 30px;
        padding: 0 15px;
    }

        .subscribe input:focus-visible {
            outline: none;
        }

    .subscribe button {
        background: #16171a;
        color: #fff;
        border: none;
        padding: 15px;
        width: 430px;
        font-size: 30px;
        margin-top: 20px;
        cursor: pointer;
    }

.found {
    padding: 60px;
    margin-top: 10px;
}

    .found p {
        /* font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif; */
        font-size: 70px;
        color: #e07d0f;
        font-weight: 800;
        margin: 0;
    }

    .found span {
        font-size: 24px;
        color: #16171a;
        display: block;
    }

.final-score button {
    background: url(../img/shape.png) no-repeat;
    background-position: center;
    font-size: 42px;
    color: #fff;
    border: none;
    height: 110px;
    width: 580px;
}

    .final-score button b {
        color: #ffaa05;
    }

.board {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 0 100px;
}

    .board .sub-inner {
        border-left: 1px solid #12743f;
        padding-left: 25px;
    }

        .board .sub-inner span {
            display: block;
            text-align: left;
            font-size: 24px;
        }

        .board .sub-inner ins {
            text-decoration: none;
            font-weight: 600;
        }

    .board .inner {
        padding-right: 25px;
    }

        .board .inner span {
            color: #e07d0f;
            font-size: 24px;
            font-weight: 600;
        }


.points {
    padding-bottom: 40px;
    padding-top: 40px;
}

    .points span {
        color: #e02014;
        font-size: 30px;
        display: block;
    }

.last {
    padding-top: 50px;
}

@media(max-width: 767px) {
    body {
        background-position: left;
        background: url(../img/bg.jpg) no-repeat;
        background-size: auto;
        margin: 0;
    }

    .main .boxes {
        padding-top: 70px;
    }

        .main .boxes button .box {
            margin-right: 0;
        }

        .main .boxes button {
            margin-right: 10px;
            border: none;
            background: transparent;
        }

        /* .main .boxes .box {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            font-size: 18px;
            padding: 2px;
        }*/

        .main .boxes .box {
            font-family: 'Poppins', sans-serif;
            background: #b85bcf;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            border: none;
            color: #fff;
            padding: 2px;
            font-size: 18px;
            margin-right: 10px;
            text-transform: uppercase;
            box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 30%);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .main .boxes .boxWin {
            font-family: 'Poppins', sans-serif;
            background: #b85bcf;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            border: none;
            color: #fff;
            padding: 2px;
            font-size: 18px;
            margin-right: 10px;
            text-transform: uppercase;
            box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 30%);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .main .boxes .boxLose {
            background: #e12014;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            border: none;
            color: #fff;
            padding: 2px;
            font-size: 18px;
            margin-right: 10px;
            text-transform: uppercase;
            box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 30%);
            display: flex;
            align-items: center;
            justify-content: center;
        }

            .main .boxes .box p {
                font-size: 20px;
            }

            .main .boxes .box span {
                font-size: 11px;
                right: 6px;
            }

        .main .boxes .boxLose span {
            font-size: 11px;
            right: 6px;
        }

        .main .boxes .boxWin span {
            font-size: 11px;
            right: 6px;
        }


    .main .logo-sec img {
        width: 250px;
        margin: auto;
    }


    .main .letters .text-box .sub-inner {
        width: 40px;
        height: 40px;
        padding: 2px;
        font-size: 18px;
    }

    .main .letters .text-box .letters-left ins {
        font-size: 14px;
        width: auto;
    }

    .main .letters .text-box span {
        font-size: 11px;
        right: 4px;
        bottom: 2px;
    }

    .main .letters .text-box {
        width: 160px;
        margin: 35px auto;
    }

    .main .numbers .num span {
        width: 28px;
        height: 7px;
        margin-right: 12px;
        padding: 8px;
        font-size: 14px;
        margin-top: 35px;
    }

    .main .content:before {
        height: auto;
       
    }

    .discard-btn button {
        font-size: 16px;
    }

    .discard-text {
        margin: 35px 0;
    }

    .current-score button {
        padding: 13px 36px;
        font-size: 22px;
    }

    .discard-text p {
        font-size: 15px;
    }

    .discard-btn {
        margin: 50px 0 30px;
    }

    .subscribe input {
        height: 45px;
        width: 230px;
        font-size: 16px;
    }

    .subscribe {
        padding-bottom: 50px;
    }

        .subscribe button {
            width: 260px;
            font-size: 16px;
            padding: 13px;
        }

    .found p {
        font-size: 36px;
    }

    .found span {
        font-size: 16px;
    }

    .found {
        margin-top: 20px;
        padding: 40px;
    }

    .final-score button {
        height: 60px;
        width: 320px;
        background-size: cover;
        font-size: 22px;
    }

    .board {
        display: block;
        padding: 40px 0 60px;
    }

        .board .inner {
            padding-right: 0;
            padding-bottom: 10px;
        }

        .board .sub-inner {
            border-left: none;
            padding-left: 0;
        }

            .board .sub-inner span {
                font-size: 15px;
                text-align: center;
            }

    .points span {
        font-size: 18px;
    }

    .points {
        padding-bottom: 20px;
    }

    .last {
        padding-top: 10px;
    }
}

@media(min-width: 768px) and (max-width: 991px) {
    .main .boxes .box {
        width: 70px;
        height: 70px;
        font-size: 55px;
    }

        .main .boxes .box span {
            font-size: 18px;
        }

    .main .numbers .num span {
        width: 60px;
        height: 27px;
        font-size: 32px;
    }

    .main .content:before {
        height: 640px;
    }

    .subscribe {
        padding-bottom: 30px;
    }
}



@media(min-width: 992px) and (max-width: 1600px) {
    .subscribe {
        padding-bottom: 30px;
    }
}
